import React from 'react';

import Layout from '../components/Layout';

export default function indexPage() {
  const isBrowser = typeof window !== 'undefined';

  return (
    <Layout fullMenu>
      <section className="wrapper style4">
        <div className="inner model">
          <br />
          <div className="col-6 col-12-medium">
            <ul className="actions stacked">
              <li>
                <h2>Library of 3D HEART models</h2>
              </li>
            </ul>
            <br />
          </div>
          <section>
            <div className="row">
              <div className="col-6 col-12-medium">
                <ul className="actions stacked">
                  <li>
                    <a
                      href="/double-aortic-arch"
                      className="button primary fit"
                    >
                      Double Aortic Arch
                    </a>
                  </li>
                  <li>
                    <a
                      href="/tetralogy-of-fallot"
                      className="button primary fit"
                    >
                      Tetralogy of Fallot
                    </a>
                  </li>
                  <li>
                    <a
                      href="/truncus-arteriosus"
                      className="button primary fit"
                    >
                      Truncus arteriosus
                    </a>
                  </li>
                  <li>
                    <a
                      href="/atrial-septal-defect"
                      className="button primary fit"
                    >
                      Atrial Septal Defect
                    </a>
                  </li>
                  <li>
                    <a
                      href="/coronary-artery-bypass-grafting"
                      className="button primary fit"
                    >
                      Coronary Artery Bypass
                    </a>
                  </li>
                  <li>
                    <a
                      href="/ascending-aortic-aneurysm"
                      className="button primary fit"
                    >
                      Ascending Aortic Aneurysm
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </div>
      </section>
    </Layout>
  );
}
